<template>
  <b-form>
    <b-row>
      <b-card
        no-body
        class="mb-0"
      >
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="activityTable"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
        >

          <template #cell(_id)="data">
            <b-link
              :to="{ name: 'userprofile', params: { username: data.item.userId.username } }"
              class="font-weight-bold d-block text-nowrap"
            >
              # {{ data.item._id }}
            </b-link>
          </template>
          <template #cell(userId)="data">
            {{ data.item.userId.username }}
          </template>
          <template #cell(createdAt)="data">
            {{ data.item.createdAt }}
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="dataMeta.currentPage"
                :total-rows="dataMeta.total"
                :per-page="dataMeta.perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="retrieveProfiles"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </b-row>
  </b-form>
</template>

<script>
import { mapActions } from 'vuex'
import router from '@/router'

import {
  BRow, BCol, BForm, BCard, BLink, BTable, BPagination,
} from 'bootstrap-vue'

export default {
  components: {
    BRow, BCol, BForm, BCard, BTable, BLink, BPagination,
  },
  data() {
    return {
      activityTable: null,
      searchQuery: '',
      dataMeta: {
        currentPage: 1,
        perPage: 14,
        total: 3,
      },
      tableColumns: [
        { key: '_id', sortable: true },
        { key: 'comment', sortable: true },
        { key: 'userId', label: 'Username', sortable: true },
        // { key: 'userId', label: 'username', sortable: true },
        { key: 'createdAt' },
      ],
    }
  },
  mounted() {
    this.retrieveUserComments()
  },
  methods: {
    ...mapActions({
      retrieveUserCommentsAction: 'profile/retrieveUserComments',
    }),
    retrieveUserComments() {
      const data = {
        id: router.currentRoute.params.id,
        page: this.dataMeta.currentPage,
        perPage: this.dataMeta.perPage,
        sortBy: 'createdAt',
        sortDesc: 1,
      }
      this.retrieveUserCommentsAction(data).then(response => {
        this.activityTable = response.data.data
        this.dataMeta.total = response.data.totalActivities
      })
    },

  },
}
</script>

<style>

</style>
