<template>
  <b-form>
    <b-row>
      <b-card
        no-body
        class="mb-0"
      >

        <!-- <div class="m-2"> -->

        <!-- Table Top -->
        <!-- <b-row> -->

        <!-- Per Page -->
        <!-- <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            /> -->

        <!-- Search -->
        <!-- <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
              </div>
            </b-col> -->
        <!-- </b-row> -->

        <!-- </div> -->

        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="activityTable"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
        >

          <template #cell(_id)="data">
            <b-link
              :to="{ name: 'userprofile', params: { usernamez: data.item.userId.username } }"
              class="font-weight-bold d-block text-nowrap"
            >
              # {{ data.item._id }}
            </b-link>
          </template>

          <template #cell(userId)="data">
            {{ data.item.userId.username }}
          </template>
          <template #cell(createdAt)="data">
            {{ data.item.createdAt }}
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="dataMeta.currentPage"
                :total-rows="dataMeta.total"
                :per-page="dataMeta.perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="retrieveActivities"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </b-row>
  </b-form>
</template>

<script>
import { mapActions } from 'vuex'
import {
  BRow, BCol, BForm, BCard, BLink, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BTable, BPagination,
} from 'bootstrap-vue'
import router from '@/router'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BCard, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BTable, BLink, BPagination,
  },
  data() {
    return {
      activityTable: null,
      searchQuery: '',
      dataMeta: {
        currentPage: 1,
        perPage: 14,
        total: 3,
      },
      tableColumns: [
        { key: '_id', sortable: true },
        { key: 'type', sortable: true },
        { key: 'isActive', sortable: true },
        { key: 'userId', label: 'username', sortable: true },
        { key: 'createdAt' },
      ],
    }
  },
  mounted() {
    this.retrieveActivities()
  },
  methods: {
    ...mapActions({
      retrieveActivitiesAction: 'profile/retreiveUserActivities',
    }),
    retrieveActivities() {
      const data = {
        id: router.currentRoute.params.id,
        page: this.dataMeta.currentPage,
        perPage: this.dataMeta.perPage,
        sortBy: 'createdAt',
        sortDesc: 1,
      }
      this.retrieveActivitiesAction(data).then(response => {
        this.activityTable = response.data.data
        this.dataMeta.total = response.data.totalActivities
      })
    },

  },
}
</script>

<style>

</style>
