<template>
  <div>

    <div v-if="userData.userType=='company'">
      <!-- Header: Personal Info -->
      <div class="d-flex">
        <feather-icon
          icon="UserIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Company Details
        </h4>
      </div>

      <!-- Form: Personal Info Form -->
      <b-form class="mt-1">
        <b-row>

          <!-- Field: Birth Date -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >

            <b-form-group
              label="Establish Date"
              label-for="birth-date"
            >
              <flat-pickr
                v-model="userData.company.establishDate"
                disabled
                class="form-control"
                :config="{ dateFormat: 'Y-m-d'}"
                placeholder="YYYY-MM-DD"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Mobile -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Mobile"
              label-for="mobile"
            >
              <b-form-input
                v-if="userData.company.companyPhone.number"
                id="mobile"
                :value="userData.company.companyPhone.code + userData.company.companyPhone.number"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Website -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Avg Monthly Sales"
              label-for="website"
            >
              <b-form-input
                id="avgMonthlySales"
                v-model="userData.company.avgMonthlySales"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Annual Sales"
              label-for="website"
            >
              <b-form-input
                id="annualSales"
                v-model="userData.company.annualSales"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="EBITDA"
              label-for="website"
            >
              <b-form-input
                v-if="userData.company.ebitda"
                id="ebitda"
                :value="userData.company.ebitda + '%'"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Assets"
              label-for="assets"
            >
              <b-form-input
                id="assets"
                v-model="userData.company.assets"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Physical Assets"
              label-for="physicalAssets"
            >
              <b-form-input
                id="physicalAssets"
                v-model="userData.company.physicalAssets"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Sale Type"
              label-for="saleType"
            >
              <b-form-input
                id="physicalAssets"
                v-model="userData.company.saleType"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="License Number"
              label-for="licenseNumber"
            >
              <b-form-input
                id="licenseNumber"
                v-model="userData.company.licenseNumber"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Company Category"
              label-for="companyCategory"
            >
              <b-form-input
                id="companyCategory"
                v-model="userData.company.companyCategory.name"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Price"
              label-for="companyCategory"
            >
              <b-form-input
                v-if="userData.company.price"
                id="companyCategory"
                :value="(userData.company.price + '   '+userData.company.currency.name)"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            class="clos"
            md="6"
            lg="12"
          >
            <b-form-group
              label="Industries"
            >
              <span
                v-for="industry in userData.company.industries"
                :key="industry._id"
              ><span style="color:blue">#</span>{{ industry.name }}

              </span>

            </b-form-group>

          </b-col>

        <!-- Field: Language -->
        <!-- <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Language"
            label-for="language"
          >
            <v-select
              v-model="userDataInfo.language"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="languageOptions"
              :clearable="false"
              input-id="language"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: Gender -->
        <!-- <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Gender"
            label-for="gender"
            label-class="mb-1"
          >
            <b-form-radio-group
              id="gender"
              v-model="userDataInfo.gender"
              :options="genderOptions"
              value="male"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: Contact Options -->
        </b-row>
      </b-form>
    </div>
    <div v-if="userData.userType=='advisor'">
      <!-- Header: Personal Info -->
      <div class="d-flex">
        <feather-icon
          icon="UserIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Details
        </h4>
      </div>

      <!-- Form: Personal Info Form -->
      <b-form class="mt-1">
        <b-row>
          <!-- Field: Mobile -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Mobile"
              label-for="mobile"
            >
              <b-form-input
                v-if="userData.advisor.advisorPhone.phoneNumber"
                id="mobile"
                :value="userData.advisor.advisorPhone.code +' ' +userData.advisor.advisorPhone.phoneNumber"
              />
            </b-form-group>
          </b-col>
          <b-col
            class="clos"
            md="6"
            lg="12"
          >
            <b-form-group
              label="Industries"
            >
              <span
                v-for="industry in userData.advisor.industries"
                :key="industry._id"
              ><span style="color:blue">#</span>{{ industry.name }}

              </span>

            </b-form-group>

          </b-col>
          <b-col
            class="clos"
            md="6"
            lg="12"
          >
            <b-form-group
              label="Skills"
            >
              <span
                v-for="skill in userData.advisor.skills"
                :key="skill._id"
              ><span style="color:blue">#</span>{{ skill.name }}

              </span>

            </b-form-group>

          </b-col>
          <b-col
            class="clos"
            md="6"
            lg="12"
          >
            <b-form-group
              label="Experiences"
            >
              <b-table
                striped
                hover
                :fields="fields"
                :items="userData.advisor.experiences"
              >
                <template #cell(location)="data">
                  {{ data.item.location.name }}
                </template>
              </b-table>
            </b-form-group>
          </b-col>
          <b-col
            class="clos"
            md="6"
            lg="12"
          >
            <b-form-group
              label="Languages"
            >
              <b-table
                striped
                hover
                :fields="languageHeader"
                :items="userData.advisor.languages"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div v-if="userData.userType=='freelancer'">
      <!-- Header: Personal Info -->
      <div class="d-flex">
        <feather-icon
          icon="UserIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Details
        </h4>
      </div>

      <!-- Form: Personal Info Form -->
      <b-form class="mt-1">
        <b-row>
          <!-- Field: Mobile -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Mobile"
              label-for="mobile"
            >
              <b-form-input
                v-if="userData.freelancer.freelancerPhone.phoneNumber"
                id="mobile"
                :value="userData.freelancer.freelancerPhone.code +' ' +userData.freelancer.freelancerPhone.phoneNumber"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Nationality"
              label-for="nationality"
            >
              <b-form-input
                v-if="userData.freelancer.nationality"
                id="nationality"
                :value="userData.freelancer.nationality.name"
              />
            </b-form-group>
          </b-col>
          <b-col
            class="clos"
            md="6"
            lg="12"
          >
            <b-form-group
              label="Industries"
            >
              <span
                v-for="industry in userData.freelancer.industries"
                :key="industry._id"
              ><span style="color:blue">#</span>{{ industry.name }}

              </span>

            </b-form-group>

          </b-col>
          <b-col
            class="clos"
            md="6"
            lg="12"
          >
            <b-form-group
              label="Skills"
            >
              <span
                v-for="skill in userData.freelancer.skills"
                :key="skill._id"
              ><span style="color:blue">#</span>{{ skill.name }}

              </span>

            </b-form-group>

          </b-col>
          <b-col
            class="clos"
            md="6"
            lg="12"
          >
            <b-form-group
              label="Certifications"
            >
              <span
                v-for="cert in userData.freelancer.certifications"
                :key="cert._id"
              ><span style="color:blue">#</span>{{( cert.name+'   '+ cert.date)}}

              </span>

            </b-form-group>

          </b-col>
          <b-col
          class="clos"
          md="6"
          lg="12"
        >
          <b-form-group
            label="Locations"
          >
            <span
              v-for="location in userData.freelancer.locations"
              :key="location._id"
            ><span style="color:blue">#</span>{{( location.stateId+'   '+ location.countryId)}}

            </span>

          </b-form-group>

        </b-col>
          <b-col
            class="clos"
            md="6"
            lg="12"
          >
            <b-form-group
              label="Experiences"
            >
              <b-table
                striped
                hover
                :fields="fields"
                :items="userData.freelancer.experiences"
              >
                <template #cell(location)="data">
                  {{ data.item.location.name }}
                </template>
              </b-table>
            </b-form-group>
          </b-col>
          <b-col
            class="clos"
            md="6"
            lg="12"
          >
            <b-form-group
              label="Languages"
            >
              <b-table
                striped
                hover
                :fields="languageHeader"
                :items="userData.freelancer.languages"
              />
            </b-form-group>
          </b-col>
          <b-col
            class="clos"
            md="6"
            lg="12"
          >
            <b-form-group
              label="Education"
            >
              <b-table
                striped
                hover
                :fields="educationHeader"
                :items="userData.freelancer.educations"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div v-if="userData.userType=='broker'">
      <!-- Header: Personal Info -->
      <div class="d-flex">
        <feather-icon
          icon="UserIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Details
        </h4>
      </div>

      <!-- Form: Personal Info Form -->
      <b-form class="mt-1">
        <b-row>
          <!-- Field: Mobile -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Mobile"
              label-for="mobile"
            >
              <b-form-input
                v-if="userData.broker.brokerPhone.phoneNumber"
                id="mobile"
                :value="userData.broker.brokerPhone.phoneCode +' ' +userData.broker.brokerPhone.phoneNumber"
              />
            </b-form-group>
          </b-col>
          <b-col
            class="clos"
            md="6"
            lg="12"
          >
            <b-form-group
              label="Industries"
            >
              <span
                v-for="industry in userData.broker.industries"
                :key="industry._id"
              ><span style="color:blue">#</span>{{ industry.name }}

              </span>

            </b-form-group>

          </b-col>
          <b-col
            class="clos"
            md="6"
            lg="12"
          >
            <b-form-group
              label="Experiences"
            >
              <b-table
                striped
                hover
                :fields="fields"
                :items="userData.broker.experiences"
              >
                <template #cell(location)="data">
                  {{ data.item.location.name }}
                </template>
              </b-table>
            </b-form-group>
          </b-col>
          <b-col
            class="clos"
            md="6"
            lg="12"
          >
            <b-form-group
              label="Languages"
            >
              <b-table
                striped
                hover
                :fields="languageHeader"
                :items="userData.broker.languages"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div v-if="userData.userType=='investor'">
      <!-- Header: Personal Info -->
      <div class="d-flex">
        <feather-icon
          icon="UserIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Investor Details
        </h4>
      </div>

      <!-- Form: Personal Info Form -->
      <b-form class="mt-1">
        <b-row>


          <!-- Field: Mobile -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Mobile"
              label-for="mobile"
            >
              <b-form-input
                v-if="userData.investor.phone.phoneNumber"
                id="mobile"
                :value="userData.investor.phone.code + userData.investor.phone.phoneNumber"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Website -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Avg Monthly Sales"
              label-for="website"
            >
              <b-form-input
                id="avgMonthlySales"
                :value="(userData.investor.investmentSize.min +' to '+ userData.investor.investmentSize.min)"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="investor Category"
              label-for="investorCategory"
            >
              <b-form-input
                id="investorCategory"
                v-model="userData.investor.investorCategory.name"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            class="clos"
            md="6"
            lg="12"
          >
            <b-form-group
              label="Industries"
            >
              <span
                v-for="industry in userData.investor.industries"
                :key="industry._id"
              ><span style="color:blue">#</span>{{ industry.name }}

              </span>

            </b-form-group>

          </b-col>
          <b-col
          class="clos"
          md="6"
          lg="12"
        >
          <b-form-group
            label="Industries"
          >
            <span
              v-for="intersted in userData.investor.interstedLocations"
              :key="intersted._id"
            ><span style="color:blue">#</span>{{ intersted.name }}

            </span>

          </b-form-group>

        </b-col>
        </b-row>
      </b-form>
    </div>



    <div v-if="userData.userType=='partner'">
      <!-- Header: Personal Info -->
      <div class="d-flex">
        <feather-icon
          icon="UserIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Details
        </h4>
      </div>

      <!-- Form: Personal Info Form -->
      <b-form class="mt-1">
        <b-row>
          <!-- Field: Mobile -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Mobile"
              label-for="mobile"
            >
              <b-form-input
                v-if="userData.partner.partnerPhone.phoneNumber"
                id="mobile"
                :value="userData.partner.partnerPhone.code +' ' +userData.partner.partnerPhone.phoneNumber"
              />
            </b-form-group>
          </b-col>
          <b-col
            class="clos"
            md="6"
            lg="12"
          >
            <b-form-group
              label="Industries"
            >
              <span
                v-for="industry in userData.partner.industries"
                :key="industry._id"
              ><span style="color:blue">#</span>{{ industry.name }}

              </span>

            </b-form-group>

          </b-col>
          <b-col
            class="clos"
            md="6"
            lg="12"
          >
            <b-form-group
              label="Experiences"
            >
              <b-table
                striped
                hover
                :fields="fields"
                :items="userData.partner.experiences"
              >
                <template #cell(location)="data">
                  {{ data.item.location.name }}
                </template>
              </b-table>
            </b-form-group>
          </b-col>
          <b-col
            class="clos"
            md="6"
            lg="12"
          >
            <b-form-group
              label="Languages"
            >
              <b-table
                striped
                hover
                :fields="languageHeader"
                :items="userData.partner.languages"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton, BTable,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, flatPickr, BFormInput, vSelect, BFormRadioGroup, BFormCheckboxGroup, BButton, BTable,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        'name',
        'company',
        'location',
        'years',
      ],
      languageHeader: [
        'name',
        'level',
      ],
      educationHeader: [
        'degree',
        'institute',
        'location',
        'date',
      ],

    }
  },
  setup() {
    return {
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
