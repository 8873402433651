<template>
  <div>
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <b-col
          v-if="userData.userType==='investor'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Company Name"
            label-for="companyName"
          >
            <b-form-input
              id="companyName"
              v-model="userData.investor.companyName"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Username -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Username"
            label-for="username"
          >
            <b-form-input
              id="username"
              v-model="userData.username"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="userData.userType==='advisor'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Name"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="userData.advisor.fullName"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="userData.userType==='freelancer'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Full Name"
            label-for="fullName"
          >
            <b-form-input
              id="fullName"
              v-model="userData.freelancer.fullName"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="userData.userType==='broker'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Full Name"
            label-for="fullName"
          >
            <b-form-input
              id="fullName"
              v-model="userData.broker.fullName"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="userData.userType==='broker'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Email Address"
            label-for="emailAddress"
          >
            <b-form-input
              id="emailAddress"
              v-model="userData.broker.emailAddress"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="userData.userType==='broker'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Owner Type"
            label-for="ownerType"
          >
            <b-form-input
              id="ownerType"
              v-model="userData.broker.ownerType"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="userData.userType==='broker'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Nationality"
            label-for="nationality"
          >
            <b-form-input
              id="nationality"
              v-model="userData.broker.nationality.name"
            />
          </b-form-group>
        </b-col>
        <b-col
        v-if="userData.userType==='partner'"
        cols="12"
        md="4"
      >
        <b-form-group
          label="Partner Email"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="userData.partner.emailAddress"
            type="email"
          />
        </b-form-group>
      </b-col>
        <b-col
          v-if="userData.userType==='broker'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="License Details"
            label-for="licenseDetails"
          >
            <b-form-input
              id="licenseDetails"
              v-model="userData.broker.licenseDetails"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="userData.userType==='freelancer'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Legal Name"
            label-for="legalName"
          >
            <b-form-input
              id="legalName"
              v-model="userData.freelancer.legalName"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="userData.userType==='freelancer'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Passport"
            label-for="passport"
          >
            <b-form-input
              id="passport"
              v-model="userData.freelancer.passport"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="userData.userType==='freelancer'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Email Address"
            label-for="emailAddress"
          >
            <b-form-input
              id="emailAddress"
              v-model="userData.freelancer.emailAddress"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="userData.userType==='freelancer'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Gender"
            label-for="gender"
          >
            <b-form-input
              id="gender"
              v-model="userData.freelancer.gender"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="userData.userType==='advisor'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Gender"
            label-for="gender"
          >
            <b-form-input
              id="gender"
              v-model="userData.advisor.gender"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="userData.userType==='advisor'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Owner Type"
            label-for="ownerType"
          >
            <b-form-input
              id="ownerType"
              v-model="userData.advisor.ownerType"
            />
          </b-form-group>
        </b-col>

        <b-col
          v-if="userData.userType==='advisor'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Email Address"
            label-for="emailAddress"
          >
            <b-form-input
              id="emailAddress"
              v-model="userData.advisor.emailAddress"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="User Type"
            label-for="userType"
          >
            <b-form-input
              id="gender"
              v-model="userData.userType"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Email -->
        <b-col
          v-if="userData.userType==='company'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Company Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="userData.company.companyEmail"
              type="email"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="userData.userType==='company'"
      >
        <b-col>
          <b-form-group
            label="Highlights"
            label-for="highlights"
          >
            <b-form-textarea
              id="highlights"
              v-model="userData.company.highlights"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="userData.userType==='company'"
      >
        <b-col>
          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="userData.company.description"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="userData.userType==='advisor'"
      >
        <b-col>
          <b-form-group
            label="Designation"
            label-for="designation"
          >
            <b-form-textarea
              id="description"
              v-model="userData.advisor.designation"
              placeholder="Enter something..."
              rows="2"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="userData.userType==='advisor'"
      >
        <b-col>
          <b-form-group
            label="Previous Project"
            label-for="previousProject"
          >
            <b-form-textarea
              id="previousProject"
              v-model="userData.advisor.previousProject"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="userData.userType==='advisor'"
      >
        <b-col>
          <b-form-group
            label="Highlights"
            label-for="highlights"
          >
            <b-form-textarea
              id="highlights"
              v-model="userData.advisor.highlights"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="userData.userType==='advisor'"
      >
        <b-col>
          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="userData.advisor.description"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="userData.userType==='freelancer'"
      >
        <b-col>
          <b-form-group
            label="Highlights"
            label-for="highlights"
          >
            <b-form-textarea
              id="highlights"
              v-model="userData.freelancer.highlights"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="userData.userType==='freelancer'"
      >
        <b-col>
          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="userData.freelancer.description"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="userData.userType==='broker'"
      >
        <b-col>
          <b-form-group
            label="Previous Work"
            label-for="previousWork"
          >
            <b-form-textarea
              id="previousWork"
              v-model="userData.broker.previousWork"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="userData.userType==='broker'"
      >
        <b-col>
          <b-form-group
            label="Highlights"
            label-for="highlights"
          >
            <b-form-textarea
              id="highlights"
              v-model="userData.broker.highlights"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="userData.userType==='broker'"
      >
        <b-col>
          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="userData.broker.description"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="userData.userType==='investor'">
        <b-col
          v-if="userData.userType==='investor'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Short Name"
            label-for="shortName"
          >
            <b-form-input
              id="shortName"
              v-model="userData.investor.shortName"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="userData.userType==='investor'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Legal name"
            label-for="legalName"
          >
            <b-form-input
              id="full-name"
              v-model="userData.investor.legalName"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Email -->
        <b-col
          v-if="userData.userType==='investor'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="investor Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="userData.investor.emailAddress"
              type="email"
            />
          </b-form-group>
        </b-col>
      </b-row>
      </b-row>
      <b-row
        v-if="userData.userType==='investor'"
      >
        <b-col>
          <b-form-group
            label="Designation"
            label-for="designation"
          >
            <b-form-textarea
              id="designation"
              v-model="userData.investor.designation"
              placeholder="Enter something..."
              rows="2"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="userData.userType==='investor'"
      >
        <b-col>
          <b-form-group
            label="Business Factors"
            label-for="businessFactors"
          >
            <b-form-textarea
              id="businessFactors"
              v-model="userData.investor.businessFactors"
              placeholder="Enter something..."
              rows="2"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row
        v-if="userData.userType==='investor'"
      >
        <b-col>
          <b-form-group
            label="Highlights"
            label-for="highlights"
          >
            <b-form-textarea
              id="highlights"
              v-model="userData.investor.highlights"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="userData.userType==='partner'">
        <b-col
          v-if="userData.userType==='partner'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Full Name"
            label-for="fullName"
          >
            <b-form-input
              id="fullName"
              v-model="userData.partner.fullName"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="userData.userType==='partner'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Owner Type"
            label-for="ownerType"
          >
            <b-form-input
              id="ownerType"
              v-model="userData.partner.ownerType"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="userData.userType==='partner'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Gender"
            label-for="gender"
          >
            <b-form-input
              id="gender"
              v-model="userData.partner.gender"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="userData.userType==='partner'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Nationality"
            label-for="nationality"
          >
            <b-form-input
              id="nationality"
              v-model="userData.partner.nationality.name"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="userData.userType==='partner'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="License Details"
            label-for="licenseDetails"
          >
            <b-form-input
              id="licenseDetails"
              v-model="userData.partner.licenseDetails"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Email -->
    
      </b-row>
      </b-row>
      <b-row
        v-if="userData.userType==='partner'"
      >
        <b-col>
          <b-form-group
            label="Highlights"
            label-for="highlights"
          >
            <b-form-textarea
              id="highlights"
              v-model="userData.partner.highlights"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="userData.userType==='partner'"
      >
        <b-col>
          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="userData.partner.description"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="userData.userType==='partner'"
      >
        <b-col>
          <b-form-group
            label="Previous Work"
            label-for="previousWork"
          >
            <b-form-textarea
              id="previousWork"
              v-model="userData.partner.previousWork"
              placeholder="Enter something..."
              rows="2"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormTextarea, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BFormTextarea,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList()
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    return {
      resolveUserRoleVariant,
      avatarText,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
