<template>
  <b-form>
    <b-row>
      <b-card
        no-body
        class="mb-0"
      >
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="activityTable"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
        >
        </b-table>
      </b-card>
    </b-row>
  </b-form>
</template>

<script>
import { mapActions } from 'vuex'
import router from '@/router'
import {
  BRow, BCol, BForm, BCard, BLink, BTable, BPagination,
} from 'bootstrap-vue'

export default {
  components: {
    BRow, BCol, BForm, BCard, BTable, BLink, BPagination,
  },
  data() {
    return {
      activityTable: null,
      searchQuery: '',
      dataMeta: {
        currentPage: 1,
        perPage: 14,
        total: 3,
      },
      tableColumns: [
        { key: '_id', sortable: true },
        { key: 'planName', label: 'Plan', sortable: true },
        { key: 'paymentMethod', label: 'Payment Method', sortable: true },
        { key: 'amountTotal', label: 'Amount', sortable: true },
        { key: 'currency', label: 'Currency', sortable: true },
        { key: 'isActive', label: 'Status', sortable: true },
        { key: 'startDate', label: 'SDate', sortable: true },
        { key: 'endDate', label: 'EDate', sortable: true },
        { key: 'paymentStatus', label: 'PYStatus', sortable: true },
        { key: 'createdAt' },
      ],
    }
  },
  mounted() {
    this.retrieveUserSubscriptions()
  },
  methods: {
    ...mapActions({
      retrieveUserSubscriptionsAction: 'profile/retrieveUserSubscriptions',
    }),
    retrieveUserSubscriptions() {
      this.retrieveUserSubscriptionsAction(router.currentRoute.params.id).then(response => {
        this.activityTable = response.data.data.subscriptions
      })
    },

  },
}
</script>

<style>

</style>
