<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <b-row v-if="!show">
      <b-col
        v-if="getUserTypeData"
        cols="12"
        md="3"
      >
        <b-card>
          <div class="demo-inline-spacing">
            <b-avatar
              v-if="getUserTypeData.isVerified"
              size="6em"
              badge="v"
              :src="getUserTypeData.imgURL"
            />
            <b-avatar
              v-else
              :src="getUserTypeData.imgURL"
            />
          </div>
          <br>
          <b-card-text>{{ getUserTypeData.roleName }}</b-card-text>
          <h5 class="text-capitalize mb-75">{{ getUserTypeData.username }}
          </h5>
          <b-card-text>{{ getUserTypeData.email }}</b-card-text>
          <b-card-text v-if="getUserTypeData.isActive">
            Activated
          </b-card-text>
          <b-card-text v-else>
            Not Active
          </b-card-text>
          <b-card-text v-if="getUserTypeData.isActive">
            Verified
          </b-card-text>
          <b-card-text v-else>
            Pending
          </b-card-text>
          <b-card-text>{{ formatDate(getUserTypeData.joined) }}</b-card-text>

        </b-card>
      </b-col>

      <b-col
        cols="12"
        md="9"
      >
        <b-tabs
          pills
          small
        >

          <!-- Tab: Account -->
          <b-tab active>
            <template #title>
              <feather-icon
                icon="UserIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Account</span>
            </template>
            <user-edit-tab-account
              :user-data="accountData"
              class="mt-2 pt-75"
            />
          </b-tab>

          <!-- Tab: Information -->
          <b-tab>
            <template #title>
              <feather-icon
                icon="InfoIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Information</span>
            </template>
            <user-edit-tab-information
              :user-data="accountData"
              class="mt-2 pt-75"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon
                icon="MapPinIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Address</span>
            </template>
            <user-edit-tab-address
              v-if="accountData.userType=='company'"
              :user-data="accountData.company"
              class="mt-2 pt-75"
            />
            <b-col
              v-if="accountData.userType=='advisor'"
              class="clos"
              md="6"
              lg="12"
            >
              <b-form-group
                label="Address"
              >
                <span
                  v-for="add in accountData.advisor.address"
                  :key="add._id"
                ><span style="color:blue">#</span>{{ add.name }}

                </span>

              </b-form-group>

            </b-col>
            <b-col
              v-if="accountData.userType=='broker'"
              class="clos"
              md="6"
              lg="12"
            >
              <b-form-group
                label="Address"
              >
                <span
                  v-for="add in accountData.broker.address"
                  :key="add._id"
                ><span style="color:blue">#</span>{{ add.name }}

                </span>

              </b-form-group>

            </b-col>
            <b-col
              v-if="accountData.userType=='partner'"
              class="clos"
              md="6"
              lg="12"
            >
              <b-form-group
                label="Address"
              >
                <span
                  v-for="add in accountData.partner.address"
                  :key="add._id"
                ><span style="color:blue">#</span>{{ add.name }}

                </span>

              </b-form-group>

            </b-col>
            <b-col
              v-if="accountData.userType=='investor'"
              class="clos"
              md="6"
              lg="12"
            >
              <b-form-group
                label="Address"
              >
                <span
                  v-for="add in accountData.investor.address"
                  :key="add._id"
                ><span style="color:blue">#</span>{{ add.name }}

                </span>

              </b-form-group>

            </b-col>
            <feather-icon
              icon="Share2Icon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Social</span>
            <user-social-accounts-vue
              class="mt-2 pt-75"
              :social-data="accountData"
            />
          </b-tab>

          <!-- Tab: Social -->
          <b-tab>
            <template #title>
              <feather-icon
                icon="ActivityIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Activities</span>
            </template>
            <user-edit-tab-social
              class="mt-2 pt-75"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon
                icon="ListIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Profiles</span>
            </template>
            <user-profiles
              class="mt-2 pt-75"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon
                icon="MessageSquareIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Comments</span>
            </template>
            <user-comments
              class="mt-2 pt-75"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon
                icon="PackageIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Subscriptions</span>
            </template>
            <user-subscription
              class="mt-2 pt-75"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon
                icon="XOctagonIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Complaints</span>
            </template>
            <user-complaints
              class="mt-2 pt-75"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon
                icon="UsersIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Followers</span>
            </template>
            <user-followers-vue
              class="mt-2 pt-75"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon
                icon="MehIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Reviews</span>
            </template>
            <user-reviews
              class="mt-2 pt-75"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon
                icon="MehIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Files</span>
            </template>
            <b-table
              striped
              hover
              :items="files"
              :fields="fields"
            >
              <template #cell(assets)="data">
                <div
                  v-viewer
                  class="images"
                >
                  <b-avatar
                    v-if="data.item.type.includes('image')"
                    variant="primary"
                    :src="data.item.fileUrl"
                    text="BV"
                  />
                </div>
                <a
                  v-if="data.item.type.includes('application')"
                  :href="data.item.fileUrl"
                  target="_blank"
                >{{ $t("dashboard.Preview") }}</a>
              </template>
              <template #cell(actions)="data">
                <b-button
                  small
                  variant="danger"
                  @click="deleteFileById(data.item._id)"
                >
                  {{ $t("dashboard.Delete") }}
                </b-button>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>
    <div v-if="show">
      <b-overlay

        :show="show"
        rounded="md"
        class="text-center rounded"
      />
    </div>
  </component>
</template>

<script>
import store from '@/store'
import moment from 'moment'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import {
  BTab, BTabs, BCard, BAlert, BLink, BRow, BAvatar, BCol, BCardText, BOverlay, BTable, BFormGroup,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import { avatarText } from '@core/utils/filter'
import userStoreModule from '../userStoreModule'
import UserEditTabAccount from './UserEditTabAccount.vue'
import UserEditTabInformation from './UserEditTabInformation.vue'
import UserEditTabAddress from './UserEditTabAddress.vue'
import UserEditTabSocial from './UserEditTabSocial.vue'
import UserProfiles from './UserProfiles.vue'
import UserSocialAccountsVue from './UserSocialAccounts.vue'
import UserComments from './UserComments.vue'
import UserSubscription from './UserSubscriptions.vue'
import UserComplaints from './UserComplaints.vue'
import UserFollowersVue from './UserFollowers.vue'
import UserReviews from './UserReviews.vue'

export default {
  components: {
    BTab,
    UserSocialAccountsVue,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BAvatar,
    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabAddress,
    UserEditTabSocial,
    UserProfiles,
    BFormGroup,
    UserFollowersVue,
    BCardText,
    BTable,
    BCol,
    UserComments,
    UserSubscription,
    UserComplaints,
    UserReviews,
    VueViewer,
    BOverlay,
  },
  setup() {
    const userData = ref(null)
    const getUserTypeData = ref(null)
    const files = ref(null)
    const address = ref(['name'])
    const fields = ref([
      {
        key: 'name',
        sortable: true,
      },
      {
        key: 'assets',
        sortable: false,
      },
    ])
    const show = ref(true)
    const accountData = ref(null)
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      // store.dispatch('profile/retreiveUserInfo', '6370e3ac488cbb6b7b27267e')
    })
    // store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
    //   .then(response => { userData.value = response.data })
    //   .catch(error => {
    //     if (error.response.status === 404) {
    //       userData.value = undefined
    //     }
    //   })
    function formatDate(value) {
      return moment(String(value)).format('LL')
    }

    store.dispatch('profile/retreiveUserInfo', router.currentRoute.params.id)
      .then(response => {
        const { data } = response.data
        // const {
        //   company = {}, broker = {}, freelancer = {}, investor = {}, advisor = {}, partner = {},
        // } = response.data.data || {}
        const formatUserInfo = {
          username: data.username,
          email: data.email,
          isActive: data.isActive,
          isVerified: data.isVerified,
          userType: data.userType,
          roleName: data.role.name,
          joined: data.createdAt,
          imgURL: '',
        }
        if (data.userType === 'broker') {
          formatUserInfo.imgURL = data.broker.files[0].fileUrl
          files.value = data.broker.files
        } else if (data.userType === 'company') {
          formatUserInfo.imgURL = data.company.files[0].fileUrl
          files.value = data.company.files
        } else if (data.userType === 'freelancer') {
          formatUserInfo.imgURL = data.freelancer.files[0].fileUrl
          files.value = data.freelancer.files
        } else if (data.userType === 'advisor') {
          formatUserInfo.imgURL = data.advisor.files[0].fileUrl
          files.value = data.advisor.files
        } else if (data.userType === 'partner') {
          formatUserInfo.imgURL = data.partner.files[0].fileUrl
          files.value = data.partner.files
        } else if (data.userType === 'investor') {
          formatUserInfo.imgURL = data.investor.files[0].fileUrl
          files.value = data.investor.files
        }
        getUserTypeData.value = formatUserInfo
        accountData.value = data
        show.value = false
      })
      .catch(error => {
        console.log(error)
        if (error.response.status === 404) {
          accountData.value = undefined
        }
      })
    return {
      userData,
      avatarText,
      getUserTypeData,
      formatDate,
      accountData,
      UserEditTabAddress,
      show,
      address,
      fields,
      files,
    }
  },
}
</script>

<style>

</style>
