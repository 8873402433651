<template>
  <div>
    <div v-if="(socialData.userType == 'company')">

      <b-form>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="socialInputs.facebook.label"
              :label-for="socialInputs.facebook.label"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    size="16"
                    :icon="socialInputs.facebook.icon"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="socialData.company.socialMedia.facebook"
                  type="url"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="socialInputs.twitter.label"
              :label-for="socialInputs.twitter.label"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    size="16"
                    :icon="socialInputs.twitter.icon"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="socialData.company.socialMedia.twitter"
                  type="url"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="socialInputs.linkedIn.label"
              :label-for="socialInputs.linkedIn.label"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    size="16"
                    :icon="socialInputs.linkedIn.icon"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="socialData.company.socialMedia.linkedIn"
                  type="url"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="socialInputs.otherLinks.label"
              :label-for="socialInputs.otherLinks.label"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    size="16"
                    :icon="socialInputs.otherLinks.icon"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="socialData.company.socialMedia.otherLinks"
                  type="url"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- <b-col class="mt-2">
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            Save Changes
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            Reset
          </b-button>
        </b-col> -->
        </b-row>
      </b-form>
    </div>
    <div v-if="(socialData.userType == 'freelancer')">

      <b-form>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="socialInputs.facebook.label"
              :label-for="socialInputs.facebook.label"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    size="16"
                    :icon="socialInputs.facebook.icon"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="socialData.freelancer.socialMedia.freelancerFacebook"
                  type="url"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="socialInputs.twitter.label"
              :label-for="socialInputs.twitter.label"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    size="16"
                    :icon="socialInputs.twitter.icon"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="socialData.freelancer.socialMedia.freelancerTwitter"
                  type="url"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="socialInputs.linkedIn.label"
              :label-for="socialInputs.linkedIn.label"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    size="16"
                    :icon="socialInputs.linkedIn.icon"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="socialData.freelancer.socialMedia.freelancerLinkedIn"
                  type="url"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="socialInputs.otherLinks.label"
              :label-for="socialInputs.otherLinks.label"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    size="16"
                    :icon="socialInputs.otherLinks.icon"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="socialData.freelancer.socialMedia.freelancerOthers"
                  type="url"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div v-if="(socialData.userType == 'broker')">

      <b-form>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="socialInputs.facebook.label"
              :label-for="socialInputs.facebook.label"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    size="16"
                    :icon="socialInputs.facebook.icon"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="socialData.broker.socialMedia.brokerFacebook"
                  type="url"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="socialInputs.twitter.label"
              :label-for="socialInputs.twitter.label"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    size="16"
                    :icon="socialInputs.twitter.icon"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="socialData.broker.socialMedia.brokerTwitter"
                  type="url"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="socialInputs.linkedIn.label"
              :label-for="socialInputs.linkedIn.label"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    size="16"
                    :icon="socialInputs.linkedIn.icon"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="socialData.broker.socialMedia.brokerLinkedIn"
                  type="url"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="socialInputs.otherLinks.label"
              :label-for="socialInputs.otherLinks.label"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    size="16"
                    :icon="socialInputs.otherLinks.icon"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="socialData.broker.socialMedia.brokerOtherLinks"
                  type="url"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div v-if="(socialData.userType == 'advisor' )">

      <b-form>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="socialInputs.facebook.label"
              :label-for="socialInputs.facebook.label"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    size="16"
                    :icon="socialInputs.facebook.icon"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="socialData.advisor.socialMedia.advisorFacebook"
                  type="url"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="socialInputs.twitter.label"
              :label-for="socialInputs.twitter.label"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    size="16"
                    :icon="socialInputs.twitter.icon"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="socialData.advisor.socialMedia.advisorTwitter"
                  type="url"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="socialInputs.linkedIn.label"
              :label-for="socialInputs.linkedIn.label"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    size="16"
                    :icon="socialInputs.linkedIn.icon"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="socialData.advisor.socialMedia.advisorLinkedIn"
                  type="url"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="socialInputs.otherLinks.label"
              :label-for="socialInputs.otherLinks.label"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    size="16"
                    :icon="socialInputs.otherLinks.icon"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="socialData.advisor.socialMedia.advisorOtherLinks"
                  type="url"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div v-if="socialData.userType=='investor'">

      <b-form>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="socialInputs.facebook.label"
              :label-for="socialInputs.facebook.label"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    size="16"
                    :icon="socialInputs.facebook.icon"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="socialData.investor.socialMedia.investorFacebook"
                  type="url"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="socialInputs.twitter.label"
              :label-for="socialInputs.twitter.label"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    size="16"
                    :icon="socialInputs.twitter.icon"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="socialData.investor.socialMedia.investorTwitter"
                  type="url"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="socialInputs.linkedIn.label"
              :label-for="socialInputs.linkedIn.label"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    size="16"
                    :icon="socialInputs.linkedIn.icon"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="socialData.investor.socialMedia.investorLinkedIn"
                  type="url"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="socialInputs.otherLinks.label"
              :label-for="socialInputs.otherLinks.label"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    size="16"
                    :icon="socialInputs.otherLinks.icon"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="socialData.investor.socialMedia.investorOtherLinks"
                  type="url"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div v-if="socialData.userType=='partner'">

      <b-form>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="socialInputs.facebook.label"
              :label-for="socialInputs.facebook.label"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    size="16"
                    :icon="socialInputs.facebook.icon"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="socialData.partner.socialMedia.partnerFacebook"
                  type="url"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="socialInputs.twitter.label"
              :label-for="socialInputs.twitter.label"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    size="16"
                    :icon="socialInputs.twitter.icon"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="socialData.partner.socialMedia.partnerTwitter"
                  type="url"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="socialInputs.linkedIn.label"
              :label-for="socialInputs.linkedIn.label"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    size="16"
                    :icon="socialInputs.linkedIn.icon"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="socialData.partner.socialMedia.partnerLinkedIn"
                  type="url"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="socialInputs.otherLinks.label"
              :label-for="socialInputs.otherLinks.label"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    size="16"
                    :icon="socialInputs.otherLinks.icon"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="socialData.partner.socialMedia.partnerOtherLinks"
                  type="url"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend,
  },
  props: {
    socialData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const userDataSocial = {
      twitter: 'https://www.twitter.com/adoptionism744',
      facebook: 'https://www.facebook.com/adoptionism664',
      instagram: 'https://www.instagram.com/adopt-ionism744',
      github: 'https://www.github.com/madop818',
      codepen: 'https://www.codepen.com/adoptism243',
      slack: '@adoptionism744',
    }

    // ? dataField is field name of `userDataSocial` object
    const socialInputs = {
      twitter: {
        icon: 'TwitterIcon',
        dataField: 'twitter',
        label: 'Twitter',
      },
      facebook: {
        icon: 'FacebookIcon',
        dataField: 'facebook',
        label: 'facebook',
      },
      linkedIn: {
        icon: 'LinkedinIcon',
        dataField: 'Linked',
        label: 'Linked',
      },
      otherLinks: {
        icon: 'GlobeIcon',
        dataField: 'otherLinks',
        label: 'Other Websites',
      },
    }

    return {
      userDataSocial,
      socialInputs,
    }
  },
}
</script>

  <style>

  </style>
